import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import { SECRET_SLUG } from 'api/groups/constants';

export type ISettingsParams = {
  groupId: SettingsParamType.Text;
  showReactions: SettingsParamType.Boolean;
  showComments: SettingsParamType.Boolean;
  postsCount: SettingsParamType.Number;
  buttonType: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  buttonType: {
    key: 'buttonType',
    getDefaultValue: () => 'rounded',
  },

  postsCount: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 5,
  },

  showReactions: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },

  showComments: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },

  groupId: {
    type: SettingsParamType.Text,
    getDefaultValue: () => SECRET_SLUG,
  },
});
